/* eslint-disable react/no-unknown-property */
const QuickLoanIcon = () => (
  <svg
    width="82"
    height="87"
    viewBox="0 0 82 87"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="47.8281"
      y="12"
      width="7.54286"
      height="35.8286"
      transform="rotate(90 47.8281 12)"
      fill="#D9D9D9"
    />
    <rect
      x="34"
      y="27"
      width="8"
      height="22"
      transform="rotate(90 34 27)"
      fill="#D9D9D9"
    />
    <rect
      x="26"
      y="43"
      width="8"
      height="14"
      transform="rotate(90 26 43)"
      fill="#D9D9D9"
    />
    <rect
      x="35"
      y="58"
      width="8"
      height="23"
      transform="rotate(90 35 58)"
      fill="#D9D9D9"
    />
    <mask id="path-5-inside-1_3244_4190" fill="white">
      <rect width="82" height="87" rx="1" />
    </mask>
    <rect
      width="82"
      height="87"
      rx="1"
      stroke="currentColor"
      stroke-width="4"
      mask="url(#path-5-inside-1_3244_4190)"
    />
  </svg>
);

export default QuickLoanIcon;
