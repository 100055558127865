import { withStyles } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { Formik, useFormik } from "formik";
import { navigate } from "gatsby";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import { preLoginApi } from "../../../api/users.ts";
import { INITIAL_APP_STATE } from "../../../commons/enum";
import CustomButton, {
  CustomButtonOutLine,
} from "../../../components/custom-button";
import DataLoadingContext from "../../../context/data-loading-context";
import {
  useCompanyInfo,
  useQueryInvalidationWithNotification,
} from "../../../hooks";
import {
  useConfirmMFASignIn,
  useIsLoggedIn,
} from "../../../hooks/user.hooks.ts";
import { primaryColor } from "../../../scss/colors.scss";
import { checkEmailVerification } from "../../../utils/email-verification";
import {
  cognitoLogin,
  isAuthenticatedUser,
} from "../../../utils/user-auth-provider";
import { EMFAType } from "../../../features/account/2fa-setting/index.tsx";
import AppRedirectOptions from "./app-redirect-options";
import axiosClient from "../../../api/axiosClient";
import { updateUserLastLoginTime } from "../../../utils/auth";
import { getMyQuickLoan } from "../../../api/scenario";

const FORM_TYPES = {
  COMMON: "COMMON",
  SOFTWARE_TOKEN_MFA: "SOFTWARE_TOKEN_MFA",
};

const CssTextField = withStyles({
  root: {
    width: "100%",
    display: "flex",
    marginBottom: "2rem",
    "& label.Mui-focused": {
      color: "#000",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: primaryColor,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: primaryColor,
      },
      "&:hover fieldset": {
        borderColor: primaryColor,
      },
      "&.Mui-focused fieldset": {
        borderColor: primaryColor,
      },
    },
  },
})(TextField);

const LoginForm = () => {
  const [formType, setFormType] = useState(FORM_TYPES.COMMON);
  const [loginUser, setLoginUser] = useState(null);

  const { data: companyInfo } = useCompanyInfo();
  const { setAppState } = useContext(DataLoadingContext);
  const isLoggedIn = useIsLoggedIn();
  const redirect =
    new URLSearchParams(window.location.search).get("redirect") || "/";
  useEffect(() => {
    if (!isLoggedIn) {
      setAppState(INITIAL_APP_STATE);
    }
  }, [isLoggedIn]);
  const queryClient = useQueryClient();
  const invalidateQueries = useQueryInvalidationWithNotification();
  const formRef = useRef("");
  const [gettingLoginStatus, setGettingLoginStatus] = useState(false);
  const [showAppRedirectOptions, setShowAppRedirectOptions] = useState(false);
  const handleLogin = () => {
    formRef.current.handleSubmit();
  };
  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleLogin();
    }
  };
  const [emailError, setEmailError] = useState("");

  const refreshAccount = useCallback(async () => {
    await invalidateQueries("isLoggedIn");
    await queryClient.invalidateQueries();
  }, [invalidateQueries, queryClient]);

  const handleLoggedInUser = useCallback(
    async (cognitoUser) => {
      axiosClient.defaults.headers.common["authentication"] =
        cognitoUser.signInUserSession.accessToken.jwtToken;
      updateUserLastLoginTime();
      const existingQuickLoanDraft = await getMyQuickLoan();
      if (existingQuickLoanDraft) {
        setShowAppRedirectOptions(true);
      } else {
        await refreshAccount();
        await navigate(redirect);
      }
    },
    [redirect, refreshAccount]
  );

  // handle 2FA form
  const confirmSignIn = useConfirmMFASignIn();
  const { enqueueSnackbar } = useSnackbar();
  const confirmMFAForm = useFormik({
    initialValues: {
      token: null,
    },
    validate: (values) => {
      const errors = {};
      if (!values.token) {
        errors.token = "Required";
      }
      return errors;
    },
    onSubmit: async (values) => {
      let mfaMethod = "";
      if (formType === FORM_TYPES.SOFTWARE_TOKEN_MFA) {
        mfaMethod = EMFAType.SOFTWARE_TOKEN_MFA;
      }
      setGettingLoginStatus(true);
      await confirmSignIn.mutateAsync(
        {
          user: loginUser,
          token: values.token,
          mfaMethod,
        },
        {
          onSuccess: async (data) => {
            setLoginUser(data);
            await handleLoggedInUser(data);
          },
          onError: (err) => {
            console.error(err);
            enqueueSnackbar("Failed to verify 2FA", {
              variant: "error",
              autoHideDuration: 3000,
            });
          },
          onSettled: () => {
            setGettingLoginStatus(false);
          },
        }
      );
    },
  });

  const handleMFAEnabledAccount = useCallback((user) => {
    if (user?.challengeName === EMFAType.SOFTWARE_TOKEN_MFA) {
      setFormType(FORM_TYPES.SOFTWARE_TOKEN_MFA);
    }
  }, []);

  const handleAppRedirectOptionsSelect = useCallback(
    async (option) => {
      setShowAppRedirectOptions(false);
      if (option === "quickloan") {
        navigate("/loan-application");
      } else {
        await refreshAccount();
        // it no use to do this, because the layout redirect to homepage anyway
        // but I still keep it as respect to legacy code, might change this later
        navigate(redirect);
      }
    },
    [refreshAccount, redirect]
  );

  const handleNavigateToSignup = () => {
    const params = new URLSearchParams(window.location.search);
    // should only redirect if there is referral token
    const token = params.get("token");
    if (!token) {
      navigate(`/signup${window.location.search}`);
      return;
    }
    navigate(
      `/signup?redirect=${encodeURIComponent(
        `/accept-invitation${window.location.search}`
      )}`
    );
  };

  return (
    <>
      {showAppRedirectOptions ? (
        <AppRedirectOptions onSelect={handleAppRedirectOptionsSelect} />
      ) : (
        <>
          {
            /* COMMON form type */
            formType === FORM_TYPES.COMMON ? (
              <Formik
                innerRef={formRef}
                initialValues={{ email: "", password: "" }}
                validate={(values) => {
                  const errors = {};
                  if (!values.email) {
                    setEmailError("Required");
                  } else if (checkEmailVerification(values.email)) {
                    setEmailError("Invalid email address");
                  } else {
                    setEmailError("");
                  }
                  if (!values.password) {
                    errors.password = "Required";
                  }
                  return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                  const params = {
                    email: values.email,
                    password: values.password,
                    companyId: companyInfo?.id || "",
                  };
                  setGettingLoginStatus(true);
                  const response = preLoginApi(params);
                  response
                    .then(async () => {
                      const cognitoUser = await cognitoLogin(
                        params.email,
                        params.password
                      );
                      setLoginUser(cognitoUser);
                      const isAuthenticated = await isAuthenticatedUser();
                      if (isAuthenticated) {
                        await handleLoggedInUser(cognitoUser);
                        return;
                      }
                      handleMFAEnabledAccount(cognitoUser);
                    })
                    .catch((err) => {
                      let errorMessage =
                        "Something was wrong. Please try again";
                      if (err?.message) errorMessage = err?.message;
                      if (err?.data?.message) errorMessage = err?.data?.message;
                      setEmailError(errorMessage);
                    })
                    .finally(() => {
                      setGettingLoginStatus(false);
                    });
                  setSubmitting(false);
                }}
              >
                {({ values, touched, handleChange, handleSubmit }) => (
                  <form className="login-form" onSubmit={handleSubmit}>
                    <CssTextField
                      label="Email"
                      type="email"
                      name="email"
                      onChange={handleChange}
                      value={values.email}
                      variant="outlined"
                      id="custom-css-outlined-input"
                      error={emailError && touched.email}
                    />

                    <CssTextField
                      id="outlined-password-input"
                      label="Password"
                      type="password"
                      name="password"
                      onKeyDown={handleKeyDown}
                      onChange={handleChange}
                      value={values.password}
                      autoComplete="current-password"
                      variant="outlined"
                      helperText={emailError && touched.email && emailError}
                      error={!!emailError && touched.email}
                    />
                    <div
                      className="forgot-password"
                      onClick={() => navigate("/loan-application")}
                      role="button"
                      tabIndex="-1"
                      onKeyPress={() => {}}
                    >
                      <span>Quick loan</span>
                    </div>
                    <div
                      className="forgot-password"
                      onClick={() => navigate("/forgot-password")}
                      role="button"
                      tabIndex="0"
                      onKeyPress={() => {}}
                    >
                      <span>Forgot Password?</span>
                    </div>

                    <div className="submit-button-group">
                      <CustomButton
                        isLoading={gettingLoginStatus}
                        label="Login"
                        onKeyPress={() => {}}
                        onClick={() => {
                          handleLogin();
                        }}
                      />
                      <CustomButtonOutLine
                        label="Signup"
                        onKeyPress={() => {}}
                        onClick={() => handleNavigateToSignup()}
                      />
                    </div>
                  </form>
                )}
              </Formik>
            ) : null
          }

          {
            /* TOTP form type */
            formType === FORM_TYPES.SOFTWARE_TOKEN_MFA ? (
              <form
                className="two-fa-confirm-form"
                onSubmit={confirmMFAForm.handleSubmit}
              >
                <h1 className="two-fa-confirm-form__headline">
                  Two-Factor authentication
                </h1>
                <CssTextField
                  label="Enter authentication code"
                  type="token"
                  name="token"
                  onChange={confirmMFAForm.handleChange}
                  value={confirmMFAForm.values.email}
                  variant="outlined"
                  id="custom-css-outlined-input"
                  error={
                    confirmMFAForm.touched.token &&
                    Boolean(confirmMFAForm.errors.token)
                  }
                  helperText={
                    confirmMFAForm.touched.token && confirmMFAForm.errors.token
                  }
                />
                <div className="submit-button-group">
                  <CustomButton
                    isLoading={gettingLoginStatus}
                    label="Confirm"
                    onKeyPress={() => {}}
                    onClick={() => {
                      confirmMFAForm.handleSubmit();
                    }}
                  />
                  <CustomButtonOutLine
                    label="Back"
                    onKeyPress={() => {}}
                    onClick={() => {
                      setFormType(FORM_TYPES.COMMON);
                    }}
                  />
                </div>
              </form>
            ) : null
          }
        </>
      )}
    </>
  );
};

export default LoginForm;
