import React from "react";
import { Box, Grid, Typography, makeStyles } from "@material-ui/core";
import { primaryColor } from "../../../scss/colors.scss";
import QuickLoanIcon from "./quick-loan-icon";
import MainAppIcon from "./main-app-icon";

const useStyles = makeStyles((theme) => ({
  optionsContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    padding: theme.spacing(4),
    width: "75%",
  },
  title: {
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: "600",
    marginBottom: "54px",
  },
  optionBox: {
    border: `1px solid ${primaryColor}`,
    borderRadius: "2px",
    padding: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    height: "100%",
  },
  iconContainer: {
    display: "flex",
    justifyContent: "center",
    marginBottom: theme.spacing(2),
    height: 85,
    color: primaryColor,
    "& svg": {
      height: "100%",
      width: "auto",
    },
  },
  optionTitle: {
    fontWeight: "bold",
    textAlign: "center",
    paddingBottom: "30px",
  },
  button: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(1, 2),
    borderRadius: "2px",
    border: `1px solid ${primaryColor}`,
    cursor: "pointer",
    color: "#040D13",
    fontSize: "14px",
    fontWeight: "700",
  },
}));

const AppRedirectOptions = ({ onSelect }) => {
  const classes = useStyles();

  const handleSelect = (option) => {
    onSelect(option);
  };

  return (
    <Box className={classes.optionsContainer}>
      <Typography variant="h6" className={classes.title}>
        What would you like to log into?
      </Typography>

      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <Box className={classes.optionBox}>
            <Typography variant="subtitle1" className={classes.optionTitle}>
              Loan Application
            </Typography>
            <Box className={classes.iconContainer}>
              <QuickLoanIcon />
            </Box>
            <Box
              onClick={() => handleSelect("quickloan")}
              className={classes.button}
            >
              Continue to Application
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box className={classes.optionBox}>
            <Typography variant="subtitle1" className={classes.optionTitle}>
              Wealth App
            </Typography>
            <Box className={classes.iconContainer}>
              <MainAppIcon />
            </Box>
            <Box
              onClick={() => handleSelect("main")}
              className={classes.button}
            >
              Continue to Wealth App
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AppRedirectOptions;
